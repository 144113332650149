.selectgroup {
	display: -ms-inline-flexbox;
	display: inline-flex;
}

.selectgroup-item {
	-ms-flex-positive: 1;
	flex-grow: 1;
	position: relative;
	+.selectgroup-item {
		margin-left: -1px;
	}
	&:not(:first-child) .selectgroup-button {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
	&:not(:last-child) .selectgroup-button {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
}

.selectgroup-input {
	opacity: 0;
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
}

.selectgroup-button {
	display: block;
	border: 1px solid $border;
	text-align: center;
	padding: 0.375rem 1rem;
	position: relative;
	cursor: pointer;
	border-radius: 5px;
	color: #9aa0ac;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-size: 0.875rem;
	line-height: 1.5rem;
	min-width: 2.375rem;
}

.selectgroup-button-icon {
	padding-left: .5rem;
	padding-right: .5rem;
	font-size: 1rem;
}

.selectgroup-input {
	&:checked+.selectgroup-button {
		z-index: 1;
		background: #f6f6fb;
	}
	&:focus+.selectgroup-button {
		z-index: 2;
		box-shadow: 0 0 0 2px rgba(98, 58, 162, 0.25);
	}
}

.selectgroup-pills {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: start;
	align-items: flex-start;
	.selectgroup-item {
		margin-right: .5rem;
		-ms-flex-positive: 0;
		flex-grow: 0;
	}
	.selectgroup-button {
		border-radius: 5px !important;
	}
}