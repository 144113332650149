@media (max-width: 575.98px) {
	.countdown-timer-wrapper {
		.timer .timer-wrapper .time {
			font-size: 25px !important;
			font-weight: bold;
			color: $white;
			width: 60px !important;
			height: 60px !important;
			border-radius: 50px;
			background: linear-gradient(to bottom right, $black-1, $black-1);
			line-height: 2.3;
			text-align: center;
			margin: 0 auto;
			margin-bottom: 5px;
			position: relative;
			overflow: hidden;
		}
		border-radius: 5px;
		margin-bottom: 30px;
		max-width: 320px !important;
	}
	.construction .display-2 {
		font-size: 2.3rem !important;
	}
	.countdown-timer-wrapper .timer {
		padding: 0px !important;
		text-align: center;
	}
}
.countdown span:first-child {
	font-size: 30px;
}