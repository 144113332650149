/*------Drop Downs-------*/

.dropup,
.dropright,
.dropdown,
.dropleft {
    position: relative;
}

.dropdown-toggle {
    &::after {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
    }
    &:empty::after {
        margin-left: 0;
    }
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 0.9375rem;
    color: #495057;
    text-align: left;
    list-style: none;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid $border;
}

.dropup {
    .dropdown-menu {
        top: auto;
        bottom: 100%;
        margin-top: 0;
        margin-bottom: 0.125rem;
    }
    .dropdown-toggle {
        &::after {
            display: inline-block;
            width: 0;
            height: 0;
            margin-left: 0.255em;
            vertical-align: 0.255em;
            content: "";
            border-top: 0;
            border-right: 0.3em solid transparent;
            border-bottom: 0.3em solid;
            border-left: 0.3em solid transparent;
        }
        &:empty::after {
            margin-left: 0;
        }
    }
}

.dropright {
    .dropdown-menu {
        top: 0;
        right: auto;
        left: 100%;
        margin-top: 0;
        margin-left: 0.125rem;
    }
    .dropdown-toggle {
        &::after {
            display: inline-block;
            width: 0;
            height: 0;
            margin-left: 0.255em;
            vertical-align: 0.255em;
            content: "";
            border-top: 0.3em solid transparent;
            border-right: 0;
            border-bottom: 0.3em solid transparent;
            border-left: 0.3em solid;
        }
        &:empty::after {
            margin-left: 0;
        }
    }
}

.dropleft {
    .dropdown-menu {
        top: 0;
        right: 100%;
        left: auto;
        margin-top: 0;
        margin-right: 0.125rem;
    }
    .dropdown-toggle {
        &::after {
            display: inline-block;
            width: 0;
            height: 0;
            margin-left: 0.255em;
            vertical-align: 0.255em;
            content: "";
        }
        &::before {
            display: inline-block;
            width: 0;
            height: 0;
            margin-right: 0.255em;
            vertical-align: 0.255em;
            content: "";
            border-top: 0.3em solid transparent;
            border-right: 0.3em solid;
            border-bottom: 0.3em solid transparent;
        }
        &:empty::after {
            margin-left: 0;
        }
        &::before {
            vertical-align: 0;
        }
    }
}

.dropdown-menu {
    &[data-popper-placement^="top"],
    &[data-popper-placement^="right"],
    &[data-popper-placement^="bottom"],
    &[data-popper-placement^="left"] {
        right: auto;
        bottom: auto;
    }
}

.dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
}

.drop-heading {
    padding: 1rem 1rem;
    color: #8f9daf;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.5rem 1.5rem !important;
    clear: both;
    font-weight: 400;
    color: #6e84a3;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.drop-icon-wrap {
    text-align: center;
    li {
        display: inline-block;
    }
    .drop-icon-item {
        display: inline-block;
        padding-bottom: 10px;
        padding-top: 10px;
        text-align: center;
        color: #878787;
        text-transform: capitalize;
        min-width: 75px;
    }
}

.drop-icon-item i {
    font-size: 20px;
    padding-bottom: 5px;
}

.drop-icon-wrap .drop-icon-item:hover {
    text-decoration: none;
    background-color: #f6f6fb;
}

.dropdown-item {
    &.user {
        font-size: 18px;
        padding: 5px;
    }
    &:hover,
    &:focus,
    &.active,
    &:active {
        text-decoration: none;
        background-color: #f6f6fb;
    }
    &.disabled,
    &:disabled {
        color: $white;
        background-color: transparent;
    }
}

.dropdown-menu.show {
    display: block;
    margin: 0;
    border: 1px solid #eaf2f6;
    box-shadow: 0 3px 9px 0 rgba(104, 113, 123, 0.15);
    border-radius: 7px;
}

.header-search-icon .dropdown-menu {
    box-shadow: 0 10px 15px rgba(104, 113, 123, 0.5);
    margin-top: 13px !important;
    border: 1px solid $border !important;
}

.dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #868e96;
    white-space: nowrap;
}

.dropdown-item-text {
    display: block;
    padding: 0.25rem 1.5rem;
    color: #212529;
}


/*-----List Of Dropdwons-----*/

btn.dropdown-toggle~.dropdown-menu {
    background-color: rgb(244, 244, 244) !important;
    background-color: rgb(255, 255, 255) !important;
    border: 0 solid rgb(66, 133, 244) !important;
    box-shadow: 0px 0px 3px rgba(25, 25, 25, 0.3) !important;
    top: 0px !important;
    margin: 0px !important;
    padding: 0px !important;
}

ul.dropdown-menu li.dropdown ul.dropdown-menu {
    background-color: rgb(244, 244, 244) !important;
    background-color: rgb(255, 255, 255) !important;
    border: 0 solid rgb(66, 133, 244) !important;
    box-shadow: 0px 0px 3px rgba(25, 25, 25, 0.3) !important;
    top: 0px !important;
    margin: 0px !important;
    padding: 0px !important;
    position: absolute !important;
    width: 100% !important;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 8;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    font-size: 14px;
    background-color: $white;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
    >li>a {
        display: block;
        padding: 8px 20px;
        clear: both;
        font-weight: 400;
        line-height: 1.428571429;
        color: #76839a;
        white-space: nowrap;
        &:hover,
        &:focus {
            text-decoration: none;
            color: #76839a;
            background-color: #f6f6fb;
        }
    }
    .divider {
        height: 1px;
        margin: 9px 0;
        overflow: hidden;
        background-color: $border;
    }
    .dropdown-plus-title {
        width: 100% !important;
        color: $default-color !important;
        padding: 6px 12px !important;
        font-weight: 500 !important;
        border: 0 solid $border !important;
        border-bottom-width: 1px !important;
        cursor: pointer !important;
    }
}

ul.dropdown-menu li.dropdown ul.dropdown-menu .dropdown-plus-title {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    line-height: 20px !important;
}

.btn.dropdown-toggle {
    &.btn-primary~.dropdown-menu .dropdown-plus-title {
        border-color: rgb(53, 126, 189) !important;
    }
    &.btn-success~.dropdown-menu .dropdown-plus-title {
        border-color: rgb(76, 174, 76) !important;
    }
    &.btn-info~.dropdown-menu .dropdown-plus-title {
        border-color: rgb(70, 184, 218) !important;
    }
    &.btn-warning~.dropdown-menu .dropdown-plus-title {
        border-color: rgb(238, 162, 54) !important;
    }
    &.btn-danger~.dropdown-menu .dropdown-plus-title {
        border-color: rgb(212, 63, 58) !important;
    }
}

.dropdown-menu-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 15px;
    border-bottom: 1px solid $border;
}

.dropdown-demo .dropdown-menu {
    position: static;
    float: none;
}

.dropdown-menu-header label {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: #76839a;
}

.dropdown-media-list .media {
    &:hover,
    &:focus {
        background-color: #f8f9fa;
    }
    padding: 12px 15px;
}

.media {
    display: flex;
    align-items: flex-start;
}

.dropdown-media-list {
    img {
        border-radius: 100%;
    }
    .media-body {
        margin-left: 15px;
        > {
            div {
                display: flex;
                align-items: center;
                justify-content: space-between;
                p {
                    margin-bottom: 0;
                    font-weight: 500;
                    color: #76839a;
                    font-size: 14px;
                }
                span {
                    font-size: 12px;
                    color: #CFCFCF;
                }
            }
            p {
                font-size: 14px;
                color: #bfbfbf;
                margin-bottom: 0;
            }
        }
    }
}

.dropdown-list-footer {
    padding: 10px 15px;
    text-align: center;
    font-size: 12px;
    border-top: 1px solid $border;
}

.dropdown-media-list {
    padding: 0;
}

@media (min-width: 768px) {
    ul.dropdown-menu li.dropdown ul.dropdown-menu .dropdown-plus-title {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }
}

@media (min-width: 768px) {
    ul.dropdown-menu li.dropdown ul.dropdown-menu {
        width: auto !important;
    }
}

.dropdown-menu-arrow:before {
    content: '';
    width: 13px;
    height: 13px;
    background: $white;
    position: absolute;
    top: -7px;
    right: 28px;
    transform: rotate(45deg);
    border-top: 1px solid $border;
    border-left: 1px solid $border;
}

.dropdown-menu.header-search:before {
    content: '';
    width: 13px;
    height: 13px;
    background: $white;
    position: absolute;
    top: -7px;
    left: 28px;
    transform: rotate(45deg);
    border-top: 1px solid $border;
    border-left: 1px solid $border;
}

.dropdown {
    display: block;
}

.header .dropdown .nav-link.icon:hover {
    background: none !important;
}

.dropdown-menu {
    box-shadow: 0 1px 2px 0 $black-05;
    min-width: 12rem;
}

.dropdown-menu-arrow.dropdown-menu-end {
    &:before,
    &:after {
        left: auto;
        right: 18px;
    }
}

.dropdown-toggle {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    &:after {
        vertical-align: 0.155em;
    }
    &:empty:after {
        margin-left: 0;
    }
}

.dropdown-icon {
    color: #8f9daf;
    margin-right: .5rem;
    margin-left: -.5rem;
    width: 1em;
    display: inline-block;
    text-align: center;
    vertical-align: -1px;
}

.btn-skew {
    transform: skew(-20deg);
}

.dropdown-toggle .dropdown-label {
    position: relative;
    top: -18px;
    left: -12px;
    background-color: #fff;
    padding: 0px 10px 0px 10px;
}

.btn-label-primary {
    color: $primary-1;
    background: transparent;
    background-image: none;
    border-color: $primary-1;
}

.btn-label-primary:hover {
    color: $primary-1;
}

.btn-label-success {
    color: $success;
    background-color: transparent;
    background-image: none;
    border-color: $success;
}

.btn-label-success:hover {
    color: $success;
}

.btn-label-info {
    color: $info;
    background-color: transparent;
    background-image: none;
    border-color: $info;
}

.btn-label-info:hover {
    color: $info;
}

.btn-label-warning {
    color: $warning;
    background-color: transparent;
    background-image: none;
    border-color: $warning;
}

.btn-label-warning:hover {
    color: $warning;
}

.btn-label-danger {
    color: $danger;
    background-color: transparent;
    background-image: none;
    border-color: $danger;
}

.btn-label-danger:hover {
    color: $danger;
}

.btn-check:active+.btn-outline-success,
.btn-check:checked+.btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active {
    background-color: $success;
    border-color: $success;
}

.btn-check:active+.btn-outline-info,
.btn-check:checked+.btn-outline-info,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show,
.btn-outline-info:active {
    color: $white;
    background-color: $info;
    border-color: $info;
}

// .dropdown-menu.dropdown-menu-end.show{
// 	left:inherit !important;
// }