/*------ Tables -----*/

.table,
.text-wrap table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
}

.table th {
    padding: 0.73rem;
    vertical-align: top;
}

.text-wrap table {
    th,
    td {
        padding: 0.75rem;
        vertical-align: top;
    }
}

.table {
    td {
        padding: 0.73rem;
        vertical-align: top;
        border-top: 0;
    }
    thead th {
        vertical-align: bottom;
        border-bottom: 1px solid $border;
        border-bottom-width: 1px;
        padding-top: .5rem;
        padding-bottom: .5rem;
        border-top: 0;
    }
}

.text-wrap table thead th {
    vertical-align: bottom;
    border-bottom: 1px solid $border;
    border-bottom-width: 1px;
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.table tbody+tbody,
.text-wrap table tbody+tbody {
    border-top: 2px solid $border;
}

.table .table,
.text-wrap table .table,
.table .text-wrap table {
    background-color: #f6f6fb;
}

.text-wrap {
    .table table,
    table table {
        background-color: #f6f6fb;
    }
}

.table-sm {
    th,
    td {
        padding: 0.3rem;
    }
}

.table-bordered,
.text-wrap table,
.table-bordered th,
.text-wrap table th,
.table-bordered td,
.text-wrap table td {
    border: 1px solid $border;
}

.table-bordered thead th,
.text-wrap table thead th,
.table-bordered thead td,
.text-wrap table thead td {
    border-bottom-width: 1px;
}

.table-borderless {
    th,
    td,
    thead th,
    tbody+tbody {
        border: 0;
    }
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.02);
}

.table-hover tbody {
    tr:hover,
    th {
        background-color: #f6f6fb;
    }
}

.table-primary {
    background-color: #d2cdf9;
    > {
        th,
        td {
            background-color: #d2cdf9;
        }
    }
}

.table-hover .table-primary:hover {
    background-color: #b7cded;
    > {
        td,
        th {
            background-color: #b7cded;
        }
    }
}

.table-secondary {
    background-color: #fdccdb;
    > {
        th,
        td {
            background-color: #fdccdb;
        }
    }
}

.table-hover .table-secondary:hover {
    background-color: #cfd2d6;
    > {
        td,
        th {
            background-color: #cfd2d6;
        }
    }
}

.table-success {
    background-color: #d2ecb8;
    > {
        th,
        td {
            background-color: #d2ecb8;
        }
    }
}

.table-hover .table-success:hover {
    background-color: #c5e7a4;
    > {
        td,
        th {
            background-color: #c5e7a4;
        }
    }
}

.table-info {
    background-color: #cbe7fb;
    > {
        th,
        td {
            background-color: #cbe7fb;
        }
    }
}

.table-hover .table-info:hover {
    background-color: #b3dcf9;
    > {
        td,
        th {
            background-color: #b3dcf9;
        }
    }
}

.table-warning {
    background-color: #f1d5a7;
    > {
        th,
        td {
            background-color: #f1d5a7;
        }
    }
}

.table-hover .table-warning:hover {
    background-color: #fae8a4;
    > {
        td,
        th {
            background-color: #fae8a4;
        }
    }
}

.table-danger {
    background-color: #f1c1c0;
    > {
        th,
        td {
            background-color: #f1c1c0;
        }
    }
}

.table-hover .table-danger:hover {
    background-color: #ecacab;
    > {
        td,
        th {
            background-color: #ecacab;
        }
    }
}

.table-light {
    background-color: #fdfdfe;
    > {
        th,
        td {
            background-color: #fdfdfe;
        }
    }
}

.table-hover .table-light:hover {
    background-color: #ececf6;
    > {
        td,
        th {
            background-color: #ececf6;
        }
    }
}

.table-dark {
    background-color: #212529;
    > {
        th,
        td {
            background-color: #212529;
        }
    }
}

.table-hover .table-dark:hover {
    background-color: #b9bbbe;
    > {
        td,
        th {
            background-color: #b9bbbe;
        }
    }
}

.table-active {
    background-color: rgba(0, 0, 0, 0.04);
    > {
        th,
        td {
            background-color: rgba(0, 0, 0, 0.04);
        }
    }
}

.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.04);
    > {
        td,
        th {
            background-color: rgba(0, 0, 0, 0.04);
        }
    }
}

.table .thead-dark th,
.text-wrap table .thead-dark th {
    color: #f6f6fb;
    background-color: #212529;
    border-color: #32383e;
}

.table .thead-light th,
.text-wrap table .thead-light th {
    color: #495057;
    background-color: #f6f6fb;
    border-color: $border;
}

.table-dark {
    color: #f6f6fb;
    background-color: #212529;
    th,
    td,
    thead th {
        color: #f6f6fb;
        border-bottom-color: $white-05 !important;
    }
    &.table-bordered {
        border: 0;
    }
}

.text-wrap table.table-dark {
    border: 0;
}

.table-dark {
    &.table-striped tbody tr:nth-of-type(odd) {
        background-color: #212529;
    }
    &.table-striped tbody tr:nth-of-type(even) {
        background-color: #272b2f;
    }
    &.table-hover tbody tr:hover {
        background-color: #212529;
    }
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        >.table-bordered {
            border: 0;
        }
    }
    .text-wrap .table-responsive-sm>table {
        border: 0;
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        >.table-bordered {
            border: 0;
        }
    }
    .text-wrap .table-responsive-md>table {
        border: 0;
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        >.table-bordered {
            border: 0;
        }
    }
    .text-wrap .table-responsive-lg>table {
        border: 0;
    }
}

@media (max-width: 1279.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        >.table-bordered {
            border: 0;
        }
    }
    .text-wrap .table-responsive-xl>table {
        border: 0;
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    >.table-bordered {
        border: 0;
    }
}

.text-wrap .table-responsive>table {
    border: 0;
}

.table-inbox {
    border: 1px solid $border;
    margin-bottom: 0;
    tr {
        border-bottom: 1px solid rgba(238, 238, 238, 0.7);
        &:last-child {
            border-bottom: 1px solid $border;
        }
        td {
            padding: 12px !important;
            &:hover {
                cursor: pointer;
            }
            .fa-star {
                &.inbox-started,
                &:hover {
                    color: #f78a09;
                }
                // color: rgba(106, 221, 196, 0.06);
            }
        }
        &.unread td {
            background: #f6f6fb;
            font-weight: 500;
        }
    }
}

.table th,
.text-wrap table th {
    color: #2e3138;
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 400;
}

.table-md {
    th,
    td {
        padding: .5rem;
    }
}

.table-vcenter {
    td,
    th {
        vertical-align: middle;
        border-top: 1px solid $border;
    }
}

.table-secondary {
    tbody+tbody,
    td,
    th,
    thead th {
        border-color: $white-2;
    }
}

.table-primary {
    tbody+tbody,
    td,
    th,
    thead th {
        border-color: $white-2;
    }
}

.table-center {
    td,
    th {
        text-align: center;
    }
}

.table-striped tbody tr {
    &:nth-of-type(odd) {
        background: transparent;
    }
    &:nth-of-type(even) {
        background-color: #f6f6fb;
    }
}

.table-calendar {
    margin: 0 0 .75rem;
    td,
    th {
        border: 0;
        text-align: center;
        padding: 0 !important;
        width: 14.28571429%;
        line-height: 2.5rem;
    }
    td {
        border-top: 0;
    }
}

.table-calendar-link {
    line-height: 2rem;
    min-width: calc(2rem + 2px);
    display: inline-block;
    border-radius: 3px;
    background: #f8f9fa;
    color: #495057;
    font-weight: 600;
    transition: .3s background, .3s color;
    position: relative;
    &:before {
        content: '';
        width: 4px;
        height: 4px;
        position: absolute;
        left: .25rem;
        top: .25rem;
        border-radius: 50px;
        background: #467fcf;
    }
    &:hover {
        color: $white;
        text-decoration: none;
        background: #467fcf;
        transition: .3s background;
        &:before {
            background: $white;
        }
    }
}

.table-header {
    cursor: pointer;
    transition: .3s color;
    &:hover {
        color: #495057 !important;
    }
    &:after {
        content: '\f0dc';
        font-family: FontAwesome;
        display: inline-block;
        margin-left: .5rem;
        font-size: .75rem;
    }
}

.table-header-asc {
    color: #495057 !important;
    &:after {
        content: '\f0de';
    }
}

.table-header-desc {
    color: #495057 !important;
    &:after {
        content: '\f0dd';
    }
}

table {
    border-collapse: collapse;
}

.table>:not(:last-child)>:last-child>* {
    border-bottom-color: $border;
}

.table>:not(caption)>*>* {
    background: transparent;
    box-shadow: none;
    // border-bottom-width: inherit;
}

.table tbody td {
    border-color: $border;
}

.table> :not(:first-child) {
    border-top: 1px solid $border;
}